define("pascalsidler/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t010PnJy",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"links\"],[12],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"menu-about\",\"index\"]],[[\"default\"],[{\"statements\":[[2,\"        home\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"menu-about\",\"cv\"]],[[\"default\"],[{\"statements\":[[2,\"        cv\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "pascalsidler/templates/application.hbs"
    }
  });

  _exports.default = _default;
});