define("pascalsidler/models/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const inflector = _emberInflector.default.inflector; // inflector soll eigentlich das plural von einem modell bilden, unter dem dann die api eine 
  // collection zur verfuegung stellt.
  // so werden zb. instanzen von 'cv' unter 'api/cvs' gesucht.
  // hier wird es allerdings verwendet, um pro modell ein 'flavour' der kirby json api
  // zu definieren.
  // siehe kirby json api repo doku

  inflector.irregular('cv', 'api/page'); // das json api plubgin von kirby stellt unter 'tree' eine seite zur verfuegung,
  // die alle unterseiten und dateien als embedded objekte im json mitsendet.

  inflector.irregular('bild', 'bilder'); // inflector.uncountable('advice');
  // Meet Ember Inspector's expectation of an export

  var _default = {};
  _exports.default = _default;
});