define("pascalsidler/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e1mnisl4",
    "block": "{\"symbols\":[],\"statements\":[[11,\"img\"],[24,0,\"bild\"],[16,\"src\",[34,0,[\"imageurl\"]]],[4,[38,1],[[32,0],\"nextImage\"],null],[12],[13],[2,\"\\n\"],[10,\"pre\"],[14,0,\"beschreibung\"],[12],[1,[35,0,[\"beschreibung\"]]],[13],[2,\"\\n\\n\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"currentImage\",\"action\"]}",
    "meta": {
      "moduleName": "pascalsidler/templates/index.hbs"
    }
  });

  _exports.default = _default;
});