define("pascalsidler/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentImageIndex: 1,
    currentImage: Ember.computed('currentImageIndex', function () {
      //todo select current image from model instead of using the store
      return this.get('store').peekRecord('bild', this.get('currentImageIndex'));
    }),
    actions: {
      nextImage() {
        let index = this.get('currentImageIndex');
        let length = this.get('model').get('length');
        index = index % length + 1;
        this.set('currentImageIndex', index);
      }

    }
  });

  _exports.default = _default;
});