define("pascalsidler/templates/cv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k2EL9i1T",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[10,\"pre\"],[12],[1,[35,2,[\"text\"]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"model\"]}",
    "meta": {
      "moduleName": "pascalsidler/templates/cv.hbs"
    }
  });

  _exports.default = _default;
});